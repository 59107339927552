/* eslint no-alert: 0 */

'use strict';

//
// Here is how to define your module
// has dependent on mobile-angular-ui
//
var app = angular.module('mobileApp', [
    'ngRoute',
    'mobile-angular-ui',
    'ngAnimate',
    // 'mobile-angular-ui.gestures'
]);

app.config(['$interpolateProvider',function($interpolateProvider) {
    $interpolateProvider.startSymbol('[[');
    $interpolateProvider.endSymbol(']]');


}]);

app.run(['$window', '$rootScope', '$http', 'userDataService', 'providerServices', 'userLogin', '$location', function($window, $rootScope, $http, userDataService, providerServices, userLogin, $location) {
    //window.$transform = $transform;

    $rootScope.imgBaseUrl = $window.imgBaseUrl;
    $rootScope.baseUrl = $window.baseUrl;
    $rootScope.providerList = $window.providerList;
    $rootScope.isLogin = $window.isLogin;

    $rootScope.ajax_counter = 0;

    if ($rootScope.isLogin) {
        console.log('is loggedin!');

        userLogin.setLoggedIn();
        providerServices.init();
    }

    $rootScope.$on("$routeChangeStart", function(event, next, current) {
        // console.log();
        var tmp_nextView = next.$$route.originalPath;
        if (tmp_nextView.match(/\/member\//g)) {
            if (!tmp_nextView.match(/login/g) && !tmp_nextView.match(/register/g)) {
                // 非login/register的member页面

                // 如果未登录 则重导航至login页面
                if (!userLogin.isLoggedIn()) $location.path('/member/login');
            } else {
                // 如果要去login page
                if (userLogin.isLoggedIn()) $location.path('/member/home');
                // $location.path("/login");
            }
        }

        $rootScope.hidingFooter = false;

        // if ($rootScope.loggedUser == null) {
        //     // no logged user, we should be going to #login
        //     if (next.templateUrl == "partials/login.html") {
        //         // already going to #login, no redirect needed
        //     } else {
        //         // not going to #login, we should redirect now
        //         $location.path("/login");
        //     }
        // }
    });


    $rootScope.goBackward = function() {
        window.history.back();
    }

    $rootScope.redirectTo = function(dest) {
        $location.path(dest);
    }

    $rootScope.hidingFooter = false;
}]);


app.factory('RequestingMarker', ['$q', '$rootScope', function($q, $rootScope) {
    var requestInterceptor = {
        'request': function(config) {
            $rootScope.ajax_counter++;

            return config;
        },

        // optional method
        'requestError': function(rejection) {
            $rootScope.ajax_counter++;

            if (canRecover(rejection)) {
                return responseOrNewPromise;
            }
            return $q.reject(rejection);
        },

        // optional method
        'response': function(response) {
            $rootScope.ajax_counter--;
            return response;
        },

        // optional method
        'responseError': function(rejection) {
            $rootScope.ajax_counter--;
            return $q.reject(rejection);
        }
    };

    return requestInterceptor;
}]);

app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('RequestingMarker');
}]);


//
// You can configure ngRoute as always, but to take advantage of SharedState location
// feature (i.e. close sidebar on backbutton) you should setup 'reloadOnSearch: false'
// in order to avoid unwanted routing.
//
app.config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/', { templateUrl: '../templates/home.html', reloadOnSearch: false });
    $routeProvider.when('/menu', { templateUrl: '../templates/menu.html', controller: "menuController", controllerAs: 'menu', reloadOnSearch: false });

    $routeProvider.when('/slot-list/:provider', { templateUrl: '../templates/gameList.html', controller: "gameListController", controllerAs: 'gameList', reloadOnSearch: false });
    $routeProvider.when('/live-list/:provider', { templateUrl: '../templates/liveList.html', controller: "liveListController", controllerAs: 'liveList', reloadOnSearch: false });
    $routeProvider.when('/download', { templateUrl: '../templates/download.html', controller: "downloadController", controllerAs: 'download', reloadOnSearch: false });
    $routeProvider.when('/promotion', { templateUrl: '../templates/promotion.html', controller: "promotionController", controllerAs: 'promotion', reloadOnSearch: false });
    $routeProvider.when('/service', { templateUrl: '../templates/customerService.html' /*, controller: "addCardController", controllerAs: 'addCard'*/ , reloadOnSearch: false });

    $routeProvider.when('/member/login', { templateUrl: '../templates/memberLogin.html', controller: "loginController", controllerAs: 'login', reloadOnSearch: false });
    $routeProvider.when('/member/register', { templateUrl: '../templates/memberRegister.html', controller: "registerController", controllerAs: 'register', reloadOnSearch: false });

    $routeProvider.when('/member/home', { templateUrl: '../templates/memberHome.html', controller: "memberController", controllerAs: 'member', reloadOnSearch: false });
    $routeProvider.when('/member/deposit', { templateUrl: '../templates/memberDeposit.html', controller: "depositController", controllerAs: 'deposit', reloadOnSearch: false });
    $routeProvider.when('/member/transfer', { templateUrl: '../templates/memberTransfer.html', controller: "transferController", controllerAs: 'transfer', reloadOnSearch: false });
    $routeProvider.when('/member/withdraw', { templateUrl: '../templates/memberWithdraw.html', controller: "withdrawController", controllerAs: 'withdraw', reloadOnSearch: false });
    $routeProvider.when('/member/accountsInfo', { templateUrl: '../templates/memberAccounts.html', controller: "accountsController", controllerAs: 'accounts', reloadOnSearch: false });
    $routeProvider.when('/member/settings', { templateUrl: '../templates/memberSettings.html', controller: "settingController", controllerAs: 'setting', reloadOnSearch: false });
    $routeProvider.when('/member/cards', { templateUrl: '../templates/memberCards.html', controller: "cardsController", controllerAs: 'cards', reloadOnSearch: false });
    $routeProvider.when('/member/favourites', { templateUrl: '../templates/memberFav.html', controller: "favouriteController", controllerAs: 'favourites', reloadOnSearch: false });

    $routeProvider.when('/member/messages', { templateUrl: '../templates/memberMessages.html', controller: "messagesController", controllerAs: 'messages', reloadOnSearch: false });

    $routeProvider.when('/member/recordList', { templateUrl: '../templates/memberRecordList.html', controller: "recordsController", controllerAs: 'records', reloadOnSearch: false });
    $routeProvider.when('/member/record/:recordType', { templateUrl: '../templates/memberRecord.html', controller: "recordController", controllerAs: 'record', reloadOnSearch: false });
    $routeProvider.when('/member/addCard', { templateUrl: '../templates/memberAddCard.html', controller: "addCardController", controllerAs: 'addCard', reloadOnSearch: false });
    $routeProvider.when('/member/redeem', { templateUrl: '../templates/memberRedeem.html', controller: "redeemController", controllerAs: 'redeem', reloadOnSearch: false });
    $routeProvider.when('/member/rescue', { templateUrl: '../templates/memberRescue.html', controller: "rescueController", controllerAs: 'rescue', reloadOnSearch: false });


    //memberDeposit.html

}]);

//
// `$touch example`
//

app.controller('registerController', ['$http', '$scope', '$location', function($http, $scope, $location) {
    var register = this;

    register.submit = function() {

        $http.post('/register', register)
            .then(function(message) {
                alert('注册成功！');
                $location.path('/member/login');
                // $controller('loginController', { $scope: $scope.$new() }).submit(register.username, register.password);
            }, function(message) {
                alert(errorMsgReader(message));
            });

    }

}]);

app.controller('loginController', ['$http', '$rootScope', '$location', '$window', 'userLogin', 'userDataService', 'providerServices',function($http, $rootScope, $location, $window, userLogin, userDataService, providerServices) {

    var login = this;
    $.extend(login, {
        username: '',
        password: '' //,
    })
    login.isLogin = $window.isLogin;

    // login.enterMemberPage = function() {
    //     // console.log(userLogin.isLoggedIn());

    //     if (userLogin.isLoggedIn()) $location.path('/member/home');
    //     else $location.path('/member/login');
    // }

    login.submit = function(username, password) {
        console.log('login called!');
        if (username && password) {
            login.password = password;
            login.username = username;
        }

        $http.post($rootScope.baseUrl + '/login', {
            password: login.password,
            username: login.username,
            recapcha: "大牛逼"
        }).then(
            function(message) {
                console.log('login successful!');
                console.log(message);

                // if (message.data == 'success') {
                    userLogin.setLoggedIn();
                    $location.path('/member/home');
                    providerServices.init();
                // }

            },
            function(message) {
                alert('登录失败！');
                console.log(message);
            }
        );
        // console.log('clicked!');
        // alert('clicked alert!');
    }

}]);




app.controller('promotionController', function() {
    var promotion = this;


});


app.controller('favouriteController', ['favouriteService', '$rootScope', '$scope', '$filter', '$window', function(favouriteService, $rootScope, $scope, $filter, $window) {
    var favourites = this;

    favouriteService.init();
    favourites.gameList = favouriteService.get();
    $scope.$on('update_favouriteService', function() {
        favourites.gameList = favouriteService.get();
    })

    favourites.open = function(game) {
        var launchUrl = '/utility/launchGame/' + ($filter('ToGameProviderName')(game.provider_id)) + 'mobile' + '/' + $.param($.extend(game, { provider: $filter('ToGameProviderName')(game.provider_id) }));
        if (window.isApp) {
            window.parent.postMessage(launchUrl, '*');
        }
        else $window.open(launchUrl, '_blank');
    }

    favourites.removeFav = favouriteService.removeFav;


    $rootScope.hidingFooter = true;

}]);


app.controller('redeemController', ['userDataService', '$http', function(userDataService, $http) {
    var redeem = this;

    redeem.userInfo = userDataService.read('userInfo');

    redeem.submit = function() {
        //
        $http.post('/member/point', {
            amount: redeem.points
        }).then(
            function(message) {
                alert('成功!');

            },
            function(message) {
                alert(errorMsgReader(message));
            }
        );

    }

}]);



app.controller('downloadController', ['$window', function($window) {
    var download = this;

    download.download = function(target) {
        switch (target) {
            case 'PT':
                $window.open('/other/Client.apk', '_blank');
                break;
            case 'GM2':
                $window.open('/other/gm2.apk', '_blank');
                break;
        }
    }


}]);

app.controller('rescueController', ['userDataService', '$rootScope', '$http', '$filter', function(userDataService, $rootScope, $http, $filter) {
    var rescue = this;

    rescue.userInfo = userDataService.read('userInfo');

    $rootScope.hidingFooter = true;

    if (!ssExtend.get('rescues')) {
        $http.get('/member/rescue').then(
            function(message) {
                if (Array.isArray(message.data) && message.data.length > 0) {
                    ssExtend.set('rescues', message.data[0]);
                }
            },
            function(message) {
                alert(errorMsgReader(message));
            }
        );
    }

    rescue.submit = function() {
        if (!ssExtend.get('rescues')) {
            alert('没有合适的救援金！');
            return false;
        }

        var tmp_date = new Date();
        //rescue.target
        if (rescue.target === 'yesterday')
            tmp_date.setDate(tmp_date.getDate() - 1);

        $http.post('/member/rescue', { issue_date: $filter('date')(tmp_date, 'yyyy-MM-dd'), bonus_id: ssExtend.get('rescues').id }).then(
            function(message) {
                alert('成功！');
                userDataService.init(true);
            },
            function(message) {
                alert(errorMsgReader(message));
            }
        );
    }

}]);


//rescueController

app.controller('cardsController', ['userDataService', '$scope', '$rootScope', 'cardServices', function(userDataService, $scope, $rootScope, cardServices) {
    var cards = this;

    // cards.userCards = userDataService.getCards();
    cardServices.init();
    $.extend(cards, cardServices.get());
    $scope.$on('update_cardServices', function() {
        $.extend(cards, cardServices.get());
    })

    cards.generaterDisplayAccount = function(accountNo) {
        var tmp_l = accountNo.length,
            tmp_output = '';
        for (var i = 0; i < tmp_l - 4; i++) {
            tmp_output += 'X';
            if (i % 4 == 3) tmp_output += ' ';
        };
        return tmp_output + accountNo.slice(-4);
    }

    $rootScope.hidingFooter = true;
}]);



app.controller('accountsController', ['$rootScope', '$scope', 'userDataService', '$http', 'providerServices', function($rootScope, $scope, userDataService, $http, providerServices) {
    var accounts = this;

    userDataService.init();
    accounts.userinfo = userDataService.read('userInfo');
    $scope.$on('update_userData', function() {
        accounts.userinfo = userDataService.read('userInfo');
    })

    accounts.providerServices = providerServices;
    accounts.providers = providerServices.list();

    $scope.$on('update_providers', function() {
        accounts.providers = providerServices.list();
        for (var i = 0; i < accounts.providers.length; i++) { accounts.providers[i].helper = null; };

    });

}]);

app.controller('settingController', ['userDataService', '$rootScope', '$scope', '$http', function(userDataService, $rootScope, $scope, $http) {
    var setting = this;

    userDataService.init();
    setting.userInfo = userDataService.read('userInfo');
    $scope.$on('update_userData', function() {
        setting.userInfo = userDataService.read('userInfo');
    })

    setting.change = function(target) {
        if (target == 'password') {
            if (setting.password && setting.password_confirmation && setting.old_password) {
                if (setting.password_confirmation != setting.password) {
                    alert('两次输入的密码不一致');
                    return false;
                } else {

                    $http.post('/member/updatePassword', {
                            password: setting.password,
                            password_confirmation: setting.password_confirmation,
                            old_password: setting.old_password
                        })
                        .then(function(message) {
                            alert('修改成功！');
                            $.extend(setting, {
                                password: null,
                                password_confirmation: null,
                                old_password: null
                            })
                        }, function(message) {
                            alert(errorMsgReader(message));
                        })


                }

            } else return false;
        } else {
            if (setting.userInfo[target]) {
                var tmp_buffer = {
                    email: setting.buffer.email ? setting.buffer.email : setting.userInfo.email,
                    mobile: setting.buffer.mobile ? setting.buffer.mobile : setting.userInfo.mobile
                }
                $http.post('/member/updateInfo', tmp_buffer)
                    .then(function(message) {
                        alert('修改成功！');
                        setting.modifying = null;
                        userDataService.update(tmp_buffer);

                    }, function(message) {
                        alert(errorMsgReader(message));
                    })

            }
        }
    }

    $rootScope.hidingFooter = true;
}])

app.controller('addCardController', ['depositServices', '$scope', '$http', 'cardServices', function(depositServices, $scope, $http, cardServices) {
    var addCard = this;

    depositServices.init();
    $.extend(addCard, depositServices.get());
    $scope.$on('update_depositServices', function() {
        $.extend(addCard, depositServices.get());
        // console.log(addCard);
    })

    cardServices.init();
    $.extend(addCard, cardServices.get());
    $scope.$on('update_cardServices', function() {
        $.extend(addCard, cardServices.get());
        // console.log(addCard);
    })


    // console.log(addCard);
    //action="" id="form_addCard" ng-submit="addCard.submit()"

    addCard.submit = function() {
        // event.preventDefault();
        cardServices.addCard(addCard.buffer);
    }
}]);

app.controller('recordsController', ['userDataService', function(userDataService) {
    var records = this;
    records.userinfo = userDataService.read('userInfo');

}]);

app.controller('recordController', ['$routeParams', '$http', function($routeParams, $http) {
    var record = this;

    record.text = {
        gameRecord: {
            title: '游戏记录',
            columns: ['局号', '平台名称', '下注金额', '派彩'],
            data: [
                'valid_rounds',
                'provider_name',
                'total_bet',
                'total_win'
            ]
        },
        depositRecord: {
            title: '存款记录',
            columns: ['存款类型', '存款金额', '状态', '时间'],
            data: [{
                    value: 'transfer_type',
                    transform: function(rawData) {
                        if (rawData == 'bankTransfer') return '银行存款';
                        else if (rawData == 'thirdParty') return '第三方支付';
                        else return '其他';
                    }
                },
                'final_amount', {
                    value: 'status',
                    transform: function(rawData) {
                        if (rawData == 'success') return '成功';
                        else return '失败';
                    }
                },
                'time'
            ]
        },
        withdrawRecord: {
            title: '取款记录',
            columns: ['申请时间', '取款金额', '状态', '备注'],
            data: ['created_at', 'amount', {
                value: 'status',
                transform: function(rawData) {
                    if (rawData == 'finish') return '完成';
                    else if (rawData == 'denied') return '拒绝';
                    else return '其他';
                }
            }, 'notes']
        },
        transferRecord: {
            title: '转账记录',
            columns: ['转账方向', '对象', '转账金额', '转账时间', '状态'],
            data: [{
                value: 'amount',
                transform: function(rawData) {
                    if (rawData > 0) return '主账户至平台';
                    else if (rawData < 0) return '平台至主账户';
                    else return '未知';
                }
            }, 'provider_name', {
                value: 'amount',
                transform: function(rawData) {
                    return Math.abs(rawData);
                }
            }, 'updated_at', {
                value: 'status',
                transform: function(rawData) {
                    if (rawData == 'success') return '成功';
                    else return '失败';
                }
            }]
        },
        promotionRecord: {
            title: '优惠记录',
            columns: ['优惠活动', '优惠金额', '申请时间', '发放日期'],
            data: ['bonus_name', 'final_amount', 'created_at', 'issue_date']

        },
        redeemRecord: {
            title: '积分兑换记录',
            columns: ['兑换积分', '兑换金额', '兑换时间'],
            data: ['point', 'money', 'created_at']
        },
        //   rescueRecord: ['loss_amount','rescue_rate','rescue_amount','rescue_date'],
        rescueRecord: {
            title: '救援金记录',
            columns: ['亏损金额', '救援比例', '救援金额', '救援时间'],
            data: ['loss_amount', 'rescue_rate', 'rescue_amount', 'rescue_date']
        },
        returnRecord: {
            title: '返水记录',
            columns: ['游戏流水', '返水比率', '返水金额', '返水时间'],
            data: [{
                value: 'provider_name',
                transform: function(rawData) {
                    return rawData ? rawData : '任何平台';
                }
            }, 'return_rate', 'return_amount', 'return_date']
        }

    }

    record.translateCell = function(row, entry) {
        // console.

        if (typeof entry == 'object') {
            return entry.transform(row[entry.value]);
        } else return row[entry];
    }

    record.type = $routeParams.recordType;

    if (!ssExtend.get('record_' + record.type)) {
        // not yet cached
        $http.get('/member/record/' + record.type + '?dataOnly=true', { headers: { 'responseType': 'json' } })
            .then(
                function(message) {
                    // console.log(message.data);
                    ssExtend.set('record_' + record.type, message.data);
                    record.data = message.data;
                },
                function(message) {
                    alert(errorMsgReader(message));
                }
            );
    } else {
        record.data = ssExtend.get('record_' + record.type);
    }

}]);

app.controller('messagesController', ['userDataService', '$http',function(userDataService, $http) {
    var messages = this;


    if (!ssExtend.get('messages')) {
        // not yet cached
        $http.get('/member/announcements?dataOnly=true', { headers: { 'responseType': 'json' } })
            .then(
                function(message) {
                    if (Array.isArray(message.data)) {
                        ssExtend.set('messages', message.data);
                        messages.messageList = message.data;
                    }
                },
                function(message) {
                    alert(errorMsgReader(message));
                }
            );
    } else {
        messages.messageList = ssExtend.get('messages');

    };

    // $rootScope.hidingFooter = true;
}]);


app.controller('withdrawController', ['$scope', 'cardServices', 'userDataService', '$http', function($scope, cardServices, userDataService, $http) {
    var withdraw = this;

    withdraw.userInfo = userDataService.read('userInfo');

    cardServices.init();
    $.extend(withdraw, cardServices.get());
    $scope.$on('update_cardServices', function() {
        $.extend(withdraw, cardServices.get());
    })

    withdraw.submit = function() {
        $http.post('/member/withdraw', {
                amount: withdraw.amount,
                card_id: withdraw.cards[withdraw.selectedNo].id
            })
            .then(function() {
                alert('申请成功!');
                withdraw.selectedNo = null;
                withdraw.amount = 0;
            }, function(message) {
                alert(errorMsgReader(message.data));
            })
    }

}]);

app.controller('transferController', ['$scope', '$rootScope', 'userDataService', 'providerServices', function($scope, $rootScope, userDataService, providerServices) {
    var transfer = this;

    userDataService.init();
    transfer.userInfo = userDataService.read('userInfo');
    $scope.$on('update_userData', function() {
        transfer.userInfo = userDataService.read('userInfo');
    })

    providerServices.init();
    transfer.providers = providerServices.list();
    $scope.$on('update_providers', function() {
        transfer.providers = providerServices.list();
        for (var i = 0; i < transfer.providers.length; i++) { transfer.providers[i].exState = null; };
        transfer.amount = {};
        // console.log(transfer.providers);
    })

    console.log(transfer.providers);
    transfer.getBalance = providerServices.getBalance;
    transfer.transfer = providerServices.transfer;
    transfer.activate = providerServices.activate;


}]);


app.controller('depositController', ['userDataService', 'depositServices', '$scope', '$rootScope', '$http', function(userDataService, depositServices, $scope, $rootScope, $http) {
    var deposit = this;

    // load in data
    userDataService.init();
    deposit.userInfo = userDataService.read('userInfo');
    $scope.$on('update_userData', function() {
        deposit.userInfo = userDataService.read('userInfo');
    })

    // 初始化 depositServices
    // 以及设置listener以免ajax返回过慢
    depositServices.init();
    $.extend(deposit, depositServices.get());
    $scope.$on('update_depositServices', function() {
        $.extend(deposit, depositServices.get());
    })


    // init datepickpicker
    $('#deposit-datetimepicker').datetimepicker({
        dateFormat: "yy-mm-dd",
        timeFormat:  "hh:mm"
    });



    deposit.providers = $rootScope.providerList;
    deposit.channel = null;


    // retrieve specific data
    if (!ssExtend.get('depositInfo')) {
        $http.get('/mobile/api/bankInfo?dataOnly=true')
            .then(function(message) {
                ssExtend.set('depositInfo', message.data);
                $.extend(deposit, message.data);
            }, function() {
                console.log('error!');
            });
    } else $.extend(deposit, ssExtend.get('depositInfo'));


    var tmp_3rdParties = {
        ebank: [],
        wechat: [],
        alipay: []
    };

    var tmp_setup = function() {
        for (var i = 0; i < deposit.third_parties.length; i++) {
            if (deposit.third_parties[i].status == 'active') {
                if (deposit.third_parties[i].ebank_status == 'active') {
                    tmp_3rdParties.ebank.push({ name: deposit.third_parties[i].name, commission: deposit.third_parties[i].ebank_commission, id: deposit.third_parties[i].id, bankId: deposit.third_parties[i].ebank_bank });
                }
                if (deposit.third_parties[i].wechat_status == 'active') {
                    tmp_3rdParties.wechat.push({ name: deposit.third_parties[i].name, commission: deposit.third_parties[i].wechat_commission, id: deposit.third_parties[i].id, bankId: deposit.third_parties[i].wechat_bank });
                }
                if (deposit.third_parties[i].alipay_status == 'active') {
                    tmp_3rdParties.alipay.push({ name: deposit.third_parties[i].name, commission: deposit.third_parties[i].alipay_commission, id: deposit.third_parties[i].id, bankId: deposit.third_parties[i].alipay_bank });
                }
            }
        }
        deposit.third_parties = tmp_3rdParties;
    }

    if (!ssExtend.get('thirdPartyInfo')) {
        $http.get('/mobile/api/thirdPartyInfo')
            .then(function(message) {
                ssExtend.set('thirdPartyInfo', message.data);
                deposit.third_parties = message.data;
                tmp_setup();

            }, function() {
                console.log('error!');
            });
    } else {
        deposit.third_parties = ssExtend.get('thirdPartyInfo');
        tmp_setup();
    }


    deposit.switchLine = function() {
        deposit.channel = JSON.parse(deposit.temp);
    }
    deposit.submit = function(method) {
        if (!deposit.channel && !deposit.buffer.bank) {
            alert('请选择线路');
            return false;
        }
        if (method === 'thirdParty') {
            window.open('/utility/thirdParty/type=thirdParty&promotion=' + deposit.buffer.promotion + '&bank=' + deposit.channel.bankId + '&amount=' + deposit.buffer.amount, '_blank');
        } else if (method === 'bankTransfer') {
            $http.post('/member/deposit', {
                    amount: deposit.buffer.amount,
                    bank: deposit.buffer.bank,
                    branch: deposit.buffer.address,
                    name: deposit.buffer.name,
                    promotion: deposit.buffer.promotion,
                    time: deposit.buffer.time + ':00',
                    type: "bankTransfer",
                    unique_identifier: deposit.buffer.serial
                })
                .then(function(message) {
                        alert('提交成功！您的凭据号码为' + message.data);
                        deposit.buffer = {};
                    },
                    function(message) {
                        alert(errorMsgReader(message));
                    });

        }
    }


    //setup
    deposit.method = 'transfer'; //default value
    $rootScope.hidingFooter = true;


}]);


app.controller('memberController', ['userDataService', '$scope',function(userDataService, $scope) {
    var member = this;

    userDataService.init();
    member.info = userDataService.read('userInfo');

    $scope.$on('update_userData', function() {
        member.info = userDataService.read('userInfo');
    })

    member.logout = function() {
        // to avoid cache issue affecting the situation of user switching accounts
        sessionStorage.clear();

        var $tmp_postform = $('<form/>').attr({
            method: 'post',
            action: '/logout'
        });
        $('body').append($tmp_postform);
        $tmp_postform.html('<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '"/>');
        $tmp_postform.submit();
    };

}]);


app.controller('menuController', ['$location', '$rootScope', '$window', function($location, $rootScope, $window) {
    var menu = this;

    menu.imgUrlBase = $rootScope.imgBaseUrl + "/mobile-specific/navigation-icons/nav";
    menu.logoBase = $rootScope.imgBaseUrl + "/logos/";
    menu.providerList = $window.providerList;

    //console.log($rootScope.providerList);

    // if ($location.search().display) menu.currentGroup = $location.search().display;
    // else 
    menu.currentGroup = 'main';

    menu.clearState = function() {
        for (var i = 0; i < menu.tiles.length; i++) {
            menu.tiles[i].state = '';
            if (menu.tiles[i].childTiles) {

                for (var j = 0; j < menu.tiles[i].childTiles.length; j++) {
                    menu.tiles[i].childTiles[j].state = '';
                }

            }
        }
    }

    menu.navGo = function() {
        if (menu.currentGroup === 'main') $location.path('/');
        else if (menu.currentGroup === 'slots') menu.currentGroup = 'main';

        // clean up child tiles and tiles 
        menu.clearState();
    }

    menu.onTileClick = function(tile) {
        //console.log(tile);

        // avoid inactive 
        if (tile.state === 'state-inactive') {
            menu.clearState();
            return false;
        }

        if (tile.filter) {
            menu.currentGroup = tile.filter;
            return false;
        }

        if (tile.href) {
            $location.path(tile.href);
            return false;
        }

        if (tile.childTiles) {
            // operation on child tiles
            for (var i = 0; i < tile.childTiles.length; i++) {
                if (tile.childTiles[i].state === 'state-display') tile.childTiles[i].state = '';
                else tile.childTiles[i].state = 'state-display';
            }

            // operation on parent tiles
            for (var i = 0; i < menu.tiles.length; i++) {
                if (menu.tiles[i] == tile) continue;
                if (menu.tiles[i].state === 'state-inactive') menu.tiles[i].state = '';
                else menu.tiles[i].state = 'state-inactive';
            }
        }

    }

    var colors = ['#FF6138','#FC7F5F','#F8B551','#FFF45C','#B28850',
                  '#CFA972','#cc3399','#9BE0CB','#A9C436','#AAA5B9', 
                  '#A5887E','#A59A7E','#A57EA0','#7EA583','#7E87A5',
                  '#6E7EBD','#AFC068','#6600ff','#FADBD8','#C39BD3' ];

    menu.tiles = [{
            name: '老虎机',
            img: menu.imgUrlBase + '1.png',
            imgProportion: '60%',
            color: '#FF6138',
            group: 'main',
            filter: 'slots'
        }, {
            name: '个人信息',
            img: menu.imgUrlBase + '2.png',
            imgProportion: '40%',
            color: '#FC7F5F',
            group: 'main',
            href: '/member/home'
        }, {
            name: '真人游戏',
            img: menu.imgUrlBase + '3.png',
            imgProportion: '40%',
            color: '#F8B551',
            group: 'main',
            childTiles: [{
                name: 'PT真人',
                color: 'red',
                width: '50%',
                href: '/live-list/PT'
            }, {
                name: 'MG真人',
                color: 'blue',
                width: '50%',
                href: '/live-list/MG'
            }]
        }, {
            name: '在线客服',
            img: menu.imgUrlBase + '4.png',
            imgProportion: '40%',
            color: '#FFF45C',
            group: 'main',
            href: '/service'
        }, {
            name: '游戏下载',
            img: menu.imgUrlBase + '5.png',
            imgProportion: '40%',
            color: '#B28850',
            group: 'main',
            href: '/download'
        }, {
            name: '优惠活动',
            img: menu.imgUrlBase + '6.png',
            imgProportion: '40%',
            color: '#CFA972',
            group: 'main',
            href: '/promotion'
        }, 

        // {
        //     name: 'PT老虎机',
        //     img: menu.logoBase + 'PT.png',
        //     imgProportion: '70%',
        //     color: '#FF6138',
        //     href: '/slot-list/PT',
        //     group: 'slots'
        // }, {
        //     name: 'MG老虎机',
        //     img: menu.logoBase + 'MG.png',
        //     imgProportion: '70%',
        //     color: '#FC7F5F',
        //     href: '/slot-list/MG',
        //     group: 'slots'
        // }, {
        //     name: 'PNG老虎机',
        //     img: menu.logoBase + 'PNG.png',
        //     imgProportion: '70%',
        //     color: '#F8B551',
        //     href: '/slot-list/PNG',
        //     group: 'slots'
        // }, {
        //     name: 'NYX老虎机',
        //     img: menu.logoBase + 'NYX.png',
        //     imgProportion: '70%',
        //     color: '#FFF45C',
        //     href: '/slot-list/NYX',
        //     group: 'slots'
        // },
        // {
        //     name: 'GameArt老虎机',
        //     img: menu.logoBase + 'GameArt.png',
        //     imgProportion: '70%',
        //     color: '#00FF7F',
        //     href: '/slot-list/GameArt',
        //     group: 'slots'
        // },
        // {
        //     name: 'NT老虎机',
        //     img: menu.logoBase + 'NT.png',
        //     imgProportion: '70%',
        //     color: '#B28850',
        //     href: '/slot-list/NT',
        //     group: 'slots'
        // }, {
        //     name: 'QT老虎机',
        //     img: menu.logoBase + 'QT.png',
        //     imgProportion: '70%',
        //     color: '#6600ff',
        //     href: '/slot-list/QT',
        //     group: 'slots'
        // },
        // {
        //     name: 'TOMHORN老虎机',
        //     img: menu.logoBase + 'TOMHORN.png',
        //     imgProportion: '70%',
        //     color: '#cc3399',
        //     href: '/slot-list/TOMHORN',
        //     group: 'slots'
        // }, {
        //     name: 'SPINOMENAL老虎机',
        //     img: menu.logoBase + 'SPINOMENAL.png',
        //     imgProportion: '70%',
        //     color: '#CFA972',
        //     href: '/slot-list/SPINOMENAL',
        //     group: 'slots'
        // }
    ];

    for (var i=0; i<menu.providerList.length; i++) {
        menu.tiles.push({
            name: menu.providerList[i].name + '老虎机',
            img: menu.logoBase + menu.providerList[i].name + '.png',
            imgProportion: '70%',
            color: colors[i],
            href: '/slot-list/' + menu.providerList[i].name,
            group: 'slots'
        })
    }

}]);

app.controller('gameListController', ['$scope', '$location', '$http', '$routeParams', '$rootScope', '$window', 'favouriteService',function($scope, $location, $http, $routeParams, $rootScope, $window, favouriteService) {
    var gameList = this;

    // get favourites
    favouriteService.init();
    gameList.isFav = favouriteService.isFav;
    gameList.toggleFav = favouriteService.toggleFav;


    gameList.providerName = $routeParams.provider;
    gameList.types = [];
    gameList.imgBaseUrl = $rootScope.imgBaseUrl;
    gameList.filterType = 'all';

    // if (ssExtend.get('listDate')) 

    if (!lsExtend.get($routeParams.provider + '_slots') || lsExtend.get($routeParams.provider + '_slots').games.length < 1 ) {
        $http.get('/mobile/api/gameList?' + $.param({ provider: $routeParams.provider, dataOnly: true }), { headers: { 'responseType': 'json' } }).then(
            function(message) {
                message.data.games = JSON.parse(message.data.games);
                message.data.types = JSON.parse(message.data.types);
                lsExtend.set($routeParams.provider + '_slots', message.data);

                $.extend(gameList, lsExtend.get($routeParams.provider + '_slots'));
            },
            function() {
                alert('server error');
            }
        );
    } else $.extend(gameList, lsExtend.get($routeParams.provider + '_slots'));



    gameList.open = function(params) {
        console.log(params);

        var tmp_provider;
        tmp_provider = window.providerMap2Name[(params.provider_id.toString())];
        // $window.open('/utility/launchGame/' + tmp_provider + 'mobile' + '/' + $.param(params), '_blank');


        var launchUrl = '/utility/launchGame/' + tmp_provider + 'mobile' + '/' + $.param(params); //findme
        if (window.isApp) {
            window.parent.postMessage(launchUrl, '*');

            // $('#gameWindow', window.parent.document).attr('src',launchUrl);
            // $('#launchGameWrapper', window.parent.document).css('zIndex',10000);            
        }
        else $window.open(launchUrl, '_blank');
    }


}]);


app.controller('liveListController', ['$http', '$routeParams', '$rootScope', '$window', 'favouriteService', function($http, $routeParams, $rootScope, $window, favouriteService) {
    var liveList = this;


    // get favourites
    favouriteService.init();
    liveList.isFav = favouriteService.isFav;
    liveList.toggleFav = favouriteService.toggleFav;

    liveList.providerName = $routeParams.provider;
    liveList.imgBaseUrl = $rootScope.imgBaseUrl;
    liveList.filterType = 'all';

    if (!lsExtend.get($routeParams.provider + '_lives')) {
        $http.get('/mobile/api/liveList?' + $.param({ provider: $routeParams.provider, dataOnly: true }), { headers: { 'responseType': 'json' } }).then(
            function(message) {
                // message.data = JSON.parse(message.data);
                lsExtend.set($routeParams.provider + '_lives', message.data);

                $.extend(liveList, { games: lsExtend.get($routeParams.provider + '_lives') });
            },
            function() {
                alert('server error');
            }
        );
    } else $.extend(liveList, { games: lsExtend.get($routeParams.provider + '_lives') });


    liveList.open = function(params) {
        console.log(params);

        var tmp_provider;
        switch (params.provider_id) {
            case 1:
                tmp_provider = 'MG';
                break;
            case 2:
                tmp_provider = 'PT';
                break;
        }

        // $window.open('/utility/launchGame/' + tmp_provider + 'mobile' + '/' + $.param(params), '_blank');

        var launchUrl = '/utility/launchGame/' + tmp_provider + 'mobile' + '/' + $.param(params);
        if (window.isApp) {
            window.parent.postMessage(launchUrl, '*');         
        }
        else $window.open(launchUrl, '_blank');
    }


    $rootScope.hidingFooter = true;

}]);


app.controller('MainController', ['$rootScope', '$scope', 'userLogin', 'userDataService', function($rootScope, $scope, userLogin, userDataService) {
    var Main = this;
}]);



app.service('userLogin', function() {
    var userLogin = {};
    var isLogin = false;

    userLogin.setLoggedIn = function() {
        isLogin = true;
    }
    userLogin.setLoggedOut = function() {
        isLogin = false;
    }
    userLogin.isLoggedIn = function() {
        return isLogin;
    }

    return userLogin;

});

app.service('userDataService', ['$http', '$rootScope', function($http, $rootScope) {
    var userDataService = {};
    var userData = {};
    $.extend(userData, {
        userInfo: null,
        favGames: null
    })


    userDataService.init = function(forced) { //直接从http上面去拿数据
        if (!userData.userInfo || forced)
            $http.get("/mobile/api/getUserInfo")
            .then(function(response) {
                userData.userInfo = response.data;
                $rootScope.$broadcast('update_userData');
            });
    }

    userDataService.update = function(news) {
        $.extend(userData.userInfo, news);
        $rootScope.$broadcast('update_userData');
    }

    userDataService.read = function(target) {
        if (userData[target]) return userData[target];
        else return {};
    }

    userDataService.getFavs = function() {
        // always call; invoked only first time
        if (!userData.favGames)
            $http.get("/member/favGame?device=mobile")
            .then(function(response) {
                userData.favGames = response.data;
                $rootScope.$broadcast('update_userData');
            });
    }

    return userDataService;
}]);

app.service('favouriteService', ['$http', '$rootScope', function($http, $rootScope) {
    var init, get, setupMap, toggleFav, removeFav, isFav;
    var data = [],
        map = {},
        flag = true;

    setupMap = function() {
        map = {};
        for (var i = 0; i < data.length; i++) {
            map[data[i].id] = true;
        }
    }

    init = function() { //直接从http上面去拿数据
        if (flag)
            $http.get("/member/favGame?device=mobile")
            .then(function(response) {
                data = response.data;
                setupMap();
                $rootScope.$broadcast('update_favouriteService');
                flag = false;
            });
    }

    get = function() {
        return data;
    }

    isFav = function(id) {
        return (map.id ? true : (map[Number(id)] ? true : false));
    }

    toggleFav = function(game) {
        // console.log('toggling in service');

        $http.post('/member/toggleLikeGame', { game_id: game.id })
            .then(
                function(message) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].id === game.id) {
                            data.splice(i, 1);
                            setupMap();
                            $rootScope.$broadcast('update_favouriteService');
                            return false;
                        }
                    }

                    // 之前没有收藏 
                    data.push(game);
                    setupMap();
                    $rootScope.$broadcast('update_favouriteService');
                },
                function(message) {
                    alert(errorMsgReader(message));
                }
            );
    }

    removeFav = function(id) {
        $http.post('/member/toggleLikeGame', { game_id: id })
            .then(
                function(message) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].id === id) {
                            data.splice(i, 1);
                            setupMap();
                            $rootScope.$broadcast('update_favouriteService');
                        }
                    }
                },
                function(message) {
                    alert(errorMsgReader(message));
                }
            );
    }

    return {
        init: init,
        get: get,
        removeFav: removeFav,
        toggleFav: toggleFav,
        isFav: isFav

    }
}]);

app.service('depositServices', ['$http', '$rootScope', function($http, $rootScope) {
    // var 
    var init, get;

    var data = {};

    init = function() {
        if (Object.keys(data).length >= 1) return false; //只拿一次
        $http.get('/mobile/api/bankInfo?dataOnly=true')
            .then(function(message) {
                message = message.data;
                $.extend(data, message);
                $rootScope.$broadcast('update_depositServices');
            }, function(message) {
                alert(errorMsgReader(message));
            })
    }

    get = function() {
        return data || {};
    }


    return {
        // isset: isset,
        init: init,
        get: get
    }
}]);


app.service('cardServices', ['$http', '$rootScope', function($http, $rootScope) {
    // var 
    var init, get, addCard;

    var data = [],
        flag = false;

    init = function() {
        if (flag) return false; //只拿一次

        $http.get('/member/manageCard?dataOnly=true')
            .then(function(message) {
                message = message.data;
                if (Array.isArray(message)) data = message;

                flag = true;
                $rootScope.$broadcast('update_cardServices');

            }, function(message) {
                alert(errorMsgReader(message));
            })
    }

    get = function() {
        return { cards: data || [] };
    }

    addCard = function(cardInfo) {
        $http.post('/member/newCard', cardInfo)
            .then(function(message) {
                alert('成功！');
                data.push(cardInfo);
                $rootScope.$broadcast('update_cardServices');
            }, function(message) {
                alert(errorMsgReader(message));
            })
    }

    return {
        // isset: isset,
        addCard: addCard,
        init: init,
        get: get
    }
}]);



app.service('providerServices', ['$http', '$rootScope', function($http, $rootScope) {
    var providerServices = {
        init: null,
        getBalance: null,
        activate: null,
        getStatus: null,
        list: null
    };

    var data = {};


    //init
    providerServices.init = function(forced) {
        console.log('providerServices.init called!');
        //providerList

        if (Object.keys(data).length < 1 || forced) {
            data = {};

            for (var i = 0; i < providerList.length; i++) {
                data[providerList[i].name] = $.extend(providerList[i], { activated: false, balance: null });
            }

            // 第一次 或者 强制刷新
            $http.get('/mobile/api/getUserProviderInfo')
                .then(function(message) {
                    for (var j = 0; j < message.data.length; j++) {
                        try {
                            data[Object.keys(message.data[j])[0]].activated = true;
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }, function(message) {
                    alert(errorMsgReader(message));
                }).then(function() {
                    $rootScope.$broadcast('update_providers');
                });;
        }

    }

    //providerServices
    providerServices.list = function() {
        var tmp_list = [];
        for (var provider in data) {
            if (data.hasOwnProperty(provider)) {
                tmp_list.push(data[provider]);
            }
        }
        return tmp_list;
    }

    providerServices.activate = function(provider) {
        if (data[provider].activated) {
            return false;
        }

        $http.post('/member/activate', JSON.stringify({ provider: provider }))
            .then(function(message) {
                data[provider].activated = true;
                alert('激活成功！');
            }, function(message) {
                alert(errorMsgReader(message));
                data[provider].helper = null;
            })
            .then(function() {
                $rootScope.$broadcast('update_providers');
            });

        return true;
    }

    providerServices.getBalance = function(provider) {
        if (!data[provider].activated) {
            //not activated!
            return false;
        }

        $http.get('/member/balance?provider=' + provider)
            .then(function(message) {
                data[provider].balance = message.data;
            }, function(message) {
                alert(errorMsgReader(message));
            })
            .then(function() {
                $rootScope.$broadcast('update_providers');
            });
        return true;
    }

    providerServices.transfer = function(providerId, providerName, direction, amount) {
        if (!data[providerName].activated) {
            //not activated!
            return false;
        }

        $http.post('/member/transfer', { provider_id: providerId, amount: (direction == 'transferingOut' ? -amount : amount) })
            .then(function(message) {
                alert('转账成功！');
                data[providerName].balance = Number(data[providerName].balance) + (direction == 'transferingOut' ? -amount : amount);

            }, function(message) {
                alert(errorMsgReader(message));
            })
            .then(function() {
                $rootScope.$broadcast('update_providers');
            });
        return true;
    }


    return {
        init: providerServices.init,
        list: providerServices.list,
        getBalance: providerServices.getBalance,
        transfer: providerServices.transfer,
        activate: providerServices.activate,

    };
}]);



app.filter('typeFilter', function() {
    return function(input, displayType) {

        if (displayType === 'all') return input;

        var displayList = [];
        for (var i = 0; i < input.length; i++) {
            if (input[i]['game_type_id'] === displayType) displayList.push(input[i]);
        }
        return displayList;
    }
})

app.filter('ToGameProviderCode', function() {
    return function(input) {
        return window.providerMap2Code[input];
    };
});

app.filter('ToGameProviderName', function() {
    return function(input) {
        input = input.toString();
        return window.providerMap2Name[input];
    };
});


app.filter('ToRecordTypeName', function() {
    return function(input) {
        switch (input) {
            case 'gameRecord':
                return '游戏记录';
            case 'depositRecord':
                return '存款记录';
            case 'transferRecord':
                return '转账记录';
            case 'withdrawRecord':
                return '提款记录';
            case 'promotionRecord':
                return '优惠记录';
            case 'returnRecord':
                return '返水记录';
            case 'redeemRecord':
                return '兑换记录';
            case 'rescueRecord':
                return '救援记录';
            default:
                return '未知记录';
        }
    };
});
